import { useRoute } from 'vue-router'

export function usePath() {
  const route = useRoute()

  const path = route?.path.split('/')[1]
  const id = route?.params?.id

  return { path, id }
}

export function userType() {
  const { path } = usePath()
  return path === 'community' ? 'employee' : 'alumni'
}

/**
 * コミュニティヘッダーのロゴ画像を取得するためのパラメータを付与する
 * @param path 画像のURL
 * @param community_id コミュニティID（未指定の場合はpathパラメータから取得する）
 * @returns path + リクエストパラメータ
 */
export function addCommunityHeaderParams(path: string, community_id: number | undefined = undefined) {
  // Pathがblob型だった場合、そのまま返す
  if (path.startsWith('blob:')) {
    return path
  }
  const { id, path: requestPath } = usePath()

  const requestParams = [`request_path=${requestPath}`, `community_id=${community_id ?? id}`, 'type=community_header']

  return path + '?' + requestParams.join('&')
}

/**
 * コミュニティサイトのヘッダーロゴ画像を取得するためのパラメータを付与する
 * @param path 画像のURL
 * @param community_id コミュニティID（未指定の場合はpathパラメータから取得する）
 * @returns path + リクエストパラメータ
 */
export function addCommunitySiteHeaderParams(path: string, community_id: number | undefined = undefined) {
  // Pathがblob型だった場合、そのまま返す
  if (path.startsWith('blob:')) {
    return path
  }
  const { id } = usePath()

  const requestParams = [`community_id=${community_id ?? id}`, 'type=community_site_header']

  return path + '?' + requestParams.join('&')
}

/**
 * ユーザのアイコン画像を取得するためのパラメータを付与する
 * @param path 画像のURL
 * @param userType ユーザ種別
 * @returns path + リクエストパラメータ
 */
export function addUserIconParams(path: string, userType: 'alumni' | 'employee' | 'yelloop' | 'community_admin' | 1 | 2 | 3) {
  // Pathがblob型だった場合、そのまま返す
  if (path.startsWith('blob:')) {
    return path
  }
  const { id, path: requestPath } = usePath()

  const requestParams = [`request_path=${requestPath}`, `community_id=${id}`, 'type=user_icon', `user_type=${userType}`]

  return path + '?' + requestParams.join('&')
}

/**
 * 投稿の添付ファイルを取得するためのパラメータを付与する
 * @param path ファイルのURL
 * @param contentType 投稿種別
 * @returns path + リクエストパラメータ
 */
export function addPostParams(path: string, contentType: number) {
  // Pathがblob型だった場合、そのまま返す
  if (path.startsWith('blob:')) {
    return path
  }
  const { id, path: requestPath } = usePath()

  const requestParams = [`request_path=${requestPath}`, `community_id=${id}`, 'type=post', `content_type=${contentType}`]

  return path + '?' + requestParams.join('&')
}
